export interface ITypes {
    id: number,
    name: string
}

export interface ITimezoneItem {
    id: number,
    label: string,
    ianaCode: string,
}

export interface IDropdownOption {
    label: string;
    value: number | string;
}

//PROMOTIONS

export interface IPromoLineItem {
    id: number;
    name: string;
    promotionalSublines: ISublineItem[];
    promotionalLineId?: number;
}

export interface ISublineItem {
    id: number;
    name: string;
    deletedAt: Date | null;
    promotionalLineId?: number;
}

//cashback and gift have the same data structure
export interface IGiftItem extends ITypes {
    price: number;
    isCashback: boolean;
}

export interface ISumItem {
    amount: number;
    gifts: IGiftItem[];
    id: number;
    isCashback: boolean;
}

export interface IPromotionsState {
    isRefreshRequired: boolean;
    isCreateNewPromotion: boolean,
    initialDataForEditPromotion: IPromotionForm | null,
    promotionTypes: ITypes[];
    promotionLines: IPromoLineItem[];
    allExistingGifts: IGiftItem[];
    allExistingCashbacks: IGiftItem[];
    purchaseSumsForTypeGift: ISumItem[];
    purchaseSumsForTypeCashback: ISumItem[];
    cashback: IGiftItem[];

    setIsRefreshRequired: (value: boolean) => void;
    setIsCreateNewPromotion: (value: boolean) => void;
    setInitialDataForEditPromotion: (values: any) => void;
    setPromotionTypes: (values: ITypes[]) => void;
    setPromotionLines: (values: IPromoLineItem[]) => void;
    setAllExistingGifts: (values: IGiftItem[]) => void;
    setAllExistingCashbacks: (values: IGiftItem[]) => void;
    setPurchaseSumsForTypeGift: (values: ISumItem[]) => void;
    setPurchaseSumsForTypeCashback: (values: ISumItem[]) => void;
    setCashback: (values: IGiftItem[]) => void;
    //TODO may be required variables isRefreshRequired for refresh promotions list for table, and isRefreshRequiredForSum - when we create a new sum with gifts
}

export type PromotionModalFormProps = {
    onClose: () => void;
    initialFormValues?: IPromotionForm | null
    refresh: () => void
}


export enum PromoTypes {
    Gift = 1,
    Cashback = 2,
    N_Plus_1 = 3
}

export interface IPromotionForm {
    id?: number;
    name: string;
    stockTypeId: number;
    purchaseAmountId: number;
    promotionalSublines: number[];
    gifts: number[];
    period: Date[];
    samples: string;
    sampleCount: number;
    samplePrice: number;
}

export interface IPromotionOutlet extends ITypes {
    budget: number;
}

export interface ISampleItem extends ITypes {
    deletedAt: Date | null;
}

export interface IPromotionTableItem {
    key: string | number;
    id: number;
    name: string;
    sampleCount: number | null;
    samplePrice: number | null;
    isStarted: boolean;
    isFinished: boolean;
    startDate: Date | null;
    endDate: Date | null;
    budget: number;
    deletedAt: Date | null;
    type: ITypes;
    outlet: IPromotionOutlet;
    purchaseAmount: {
        id: number;
        amount: number;
    } | null;
    promotionalSublines: ISublineItem[];
    samples: ISampleItem[];
    gifts: IGiftItem[];
}

//PROMOTION SETTINGS
export enum PromotionSettingModules {
    PromoLines = 0,
    GiftsAndCashback,
}

export type PromoLineArg = {
    name: string;
    sublines?: string[];
}

export type SublineArg = {
    name: string;
    promotionalLineId: number;
}

export type GiftArg = {
    name: string;
    price: number;
    isCashback: boolean;
}

export type PurchaseSumArg = {
    amount: number;
    gifts: number[];
    isCashback: boolean;
    id?: number
}

export type PromoLineModalProps = {
    onClose: () => void;
    initialFormValues?: IPromoLineItem | null;
    refresh: () => void;
}

export type GiftFormModalProps = {
    onClose: () => void;
    initialFormValues?: ISumItem | null;
    refresh: () => void;
}

//DEMO MATERIALS
export interface IDemoMaterialsState {
    initialDataForCreateReference: IReferenceFormItem | null,
    initialDataForEditReference: IReferenceFormItem | null,
    groupOptions: IDropdownOption[],
    isRefreshRequired: boolean,
    setInitialDataForCreateReference: (values: IReferenceFormItem | null) => void
    setInitialDataForEditReference: (values: IReferenceFormItem | null) => void,
    setGroupOptions: (values: IDropdownOption[]) => void,
    setIsRefreshRequired: (value: boolean) => void,
}

export type DemoMaterialsModalFormProps = {
    onClose: () => void;
}

export interface IReferenceFormItem {
    id?: string | number;
    name: string;
    description: string;
    referenceGroupId: number | null;
}

export interface IReferenceItem {
    id: number;
    name: string;
    description: string;
}

export interface ITableItem extends IReferenceItem {
    referenceGroupId: number;
    groupName: string;
    key: number;
}

export type DemoMaterialsListResponse = {
    data: Array<{ id: number, name: string, referenceItems: IReferenceItem[] }>;
    status: number;
}
