import React from "react";
import { ColumnsType } from "antd/es/table";
import { ISumItem } from "@hooks/interfaces";
import styles from "@pages/PromotionSettings/style.module.scss";
import { EditOutlined } from "@ant-design/icons";
import { Table } from "antd";

type GiftsTableProps = {
    data: ISumItem[],
    loading: boolean;
    onRowClick: (rowData: ISumItem) => void
}

export const GiftsTable: React.FC<GiftsTableProps> = ({data, loading, onRowClick}) => {

    const columns: ColumnsType<ISumItem> = [
        {
            title: "Cумма покупки",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Тип",
            key: "isCashback",
            dataIndex: "isCashback",
            render: (isCashback: boolean) => <div>{isCashback ? "Кэшбэк" : "Подарок"}</div>
        },
        {
            title: "Подарки",
            key: "gifts",
            dataIndex: "gifts",
            render: (_, record) => {
                const parsedData = record?.gifts?.map((g) => g.name)?.join(', ') || "";
                return <div>{parsedData}</div>
            }
        },
        {
            title: "Действия",
            width: "200px",
            align: "center",
            render: (_, record: ISumItem) => (
                <div className={styles.icons}>
                    <EditOutlined onClick={() => {
                        onRowClick(record);
                    }} className={styles.editIcon} />
                </div>
            )
        },
    ]

    return (
        <div className={styles.tableWrapper}>
            <Table columns={columns} dataSource={data} loading={loading} />
        </div>
    );
};
