import React, { useMemo } from "react";
import { ISublineItem, PromoTypes } from "@hooks/interfaces";
import { usePromotions } from "@hooks/useContext";

type Props = {
    mainPromoLineValues: number[];
    type: number;
    purchaseSumValue: number
}

export const usePromotionPickerOptions = (props: Props) => {
    const {mainPromoLineValues, type, purchaseSumValue} = props
    const {
        promotionTypes,
        promotionLines,
        purchaseSumsForTypeGift,
        purchaseSumsForTypeCashback,
    } = usePromotions();

    const promoTypesOptions = useMemo(() => promotionTypes?.map((t) => ({
        label: t.name,
        value: t.id
    })), [promotionTypes]) || [];

    const promoLinesOptions = useMemo(() => promotionLines?.map((line) => ({
        value: line.id,
        label: line.name
    })), [promotionLines]);


    const promoSublineOptions = useMemo(() => {
        const options: ISublineItem[] = promotionLines?.filter((line) => mainPromoLineValues?.includes(line.id))?.map((line) => line.promotionalSublines)?.flat() || [];

        return options.map((subline) => ({
            label: subline.name,
            value: subline.id
        }));
    }, [promotionLines, mainPromoLineValues]) || [];

    const sumOptions = useMemo(() => {
        switch (type) {
            case PromoTypes.Gift:
                return purchaseSumsForTypeGift?.map((item) => ({ value: item.id, label: item.amount })) || [];
            case PromoTypes.Cashback:
                return purchaseSumsForTypeCashback?.map((item) => ({ value: item.id, label: item.amount })) || [];
            default:
                return [];
        }
    }, [purchaseSumsForTypeGift, purchaseSumsForTypeCashback, type]);

    const giftsOptionForTypeGift = useMemo(() => purchaseSumsForTypeGift
        ?.find((item) => item.id === purchaseSumValue)
        ?.gifts?.map((g) => ({
            value: g.id,
            label: g.name
        })), [purchaseSumsForTypeGift, purchaseSumValue]);


    const cashbackOptions = useMemo(() => purchaseSumsForTypeCashback
        ?.find((item) => item.id === purchaseSumValue)?.gifts
        ?.map((g) => ({
            value: g.id,
            label: g.price
        })), [purchaseSumsForTypeCashback, purchaseSumValue]) || [];


    //only for  promo with type GIFT
    const getPriceString = (values: number[]) => {
        return purchaseSumsForTypeGift.find((item) => item.id === purchaseSumValue)?.gifts?.reduce((acc: number[], current) => {
            if(values.includes(current.id)) {
                return [...acc, current.price];
            }

            return acc
        }, []).join(',');
    }
    return {
        promoTypesOptions,
        promoLinesOptions,
        promoSublineOptions,
        sumOptions,
        giftsOptionForTypeGift,
        cashbackOptions,
        getPriceString
    }
};
