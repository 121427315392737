import React, { useState, useEffect, useContext, useCallback, createContext, useMemo } from "react";
import axios from "@axios";
import {
    IPromotionsState,
    IDemoMaterialsState,
    ITypes,
    IPromotionForm,
    IPromoLineItem,
    IGiftItem, ISumItem
} from "@hooks/interfaces";
import { tr } from "date-fns/locale";
import { usePromotionsAPI } from "@api/promotions";

export interface UserProps {
    id: number;
    email?: string;
    hash?: string;
    isAdmin: boolean;
    fullName: string;
    lastName: string;
    firstName: string;
    middleName?: string;
}

export interface ContextProps {
    profile: {
        user: UserProps | null;
        logout: () => void;
        setUser: React.Dispatch<React.SetStateAction<UserProps | null>>;
        loading: boolean;
    };
    promotions: IPromotionsState;
    storage: {
        mediaTypes: ITypes[];
        loadMediaTypes: () => void;
    };

    demoMaterials: IDemoMaterialsState;
}

const AppContext = createContext<ContextProps>({
    profile: {
        user: null,
        logout: () => {
            /* empty */
        },
        setUser: () => {
            /* empty */
        },
        loading: true
    },
    promotions: {
        isRefreshRequired: false,
        isCreateNewPromotion: false,
        initialDataForEditPromotion: null,
        promotionTypes: [],
        promotionLines: [],
        allExistingGifts: [],
        allExistingCashbacks: [],
        purchaseSumsForTypeGift: [],
        purchaseSumsForTypeCashback: [],
        cashback: [],

        setIsRefreshRequired: (value: boolean) => {},
        setIsCreateNewPromotion: (value: boolean) => {},
        setInitialDataForEditPromotion: (values: any) => {},
        setPromotionTypes: (values: any[]) => {},
        setPromotionLines: (values: any[]) => {},
        setAllExistingGifts: (values: any[]) => {},
        setAllExistingCashbacks: (values: any[]) => {},
        setPurchaseSumsForTypeGift: (values: any[]) => {},
        setPurchaseSumsForTypeCashback: (values: any[]) => {},
        setCashback: (values: any[]) => {},
    },
    storage: {
        mediaTypes: [],
        loadMediaTypes: () => {
            /* empty */
        }
    },
    demoMaterials: {
        initialDataForCreateReference: null,
        initialDataForEditReference: null,
        groupOptions: [],
        isRefreshRequired: false,
        setInitialDataForCreateReference: () => {
        },
        setInitialDataForEditReference: () => {
        },
        setGroupOptions: () => {
        },
        setIsRefreshRequired: () => {
        }
    }
});

export const ProvideAppContext: React.FunctionComponent = ({ children }) => {
    const profile = useProvideProfile();
    const storage = useProvideStorage();
    const promotions = useProvidePromotions();
    const demoMaterials = useProvideDemoMaterials();
    return <AppContext.Provider value={{ profile, storage, demoMaterials, promotions }}>{children}</AppContext.Provider>;
};

export const useProfile = () => {
    const { profile } = useContext(AppContext);
    return profile;
};
export const useStorage = () => {
    const { storage } = useContext(AppContext);
    return storage;
};

export const usePromotions = () => {
    const {promotions} = useContext(AppContext);
    return promotions;
}

export const useDemoMaterials = () => {
    const { demoMaterials } = useContext(AppContext);
    return demoMaterials;
};

const useProvideProfile = () => {
    const [user, setUser] = useState<UserProps | null>(null);
    const [loading, setLoading] = useState(true);

    const logout = useCallback(async () => {
        localStorage.setItem("token", "");
        setUser(null);
    }, []);

    const loadUser = useCallback(async () => {
        try {
            const res = await axios.get("user/me");
            setUser(res.data);
        } catch {
            logout();
        }
    }, [logout]);

    const load = useCallback(async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            if (token) {
                await loadUser();
            }
        } catch {
            /* empty */
        } finally {
            setLoading(false);
        }
    }, [loadUser]);

    useEffect(() => {
        load();
    }, [load]);

    return { user, logout, setUser, loading };
};

const useProvideStorage = () => {
    const [mediaTypes, setMediaTypes] = React.useState<ITypes[]>([]);

    const loadMediaTypes = async () => {
        try {
            const res = await axios.get("/media/types");
            setMediaTypes(res.data);
        } catch {
            /* empty */
        }
    };

    return {
        mediaTypes,
        loadMediaTypes
    };
};


const useProvidePromotions = () : IPromotionsState => {
   const [isRefreshRequired, setIsRefreshRequired] = React.useState(false);
    const [isCreateNewPromotion, setIsCreateNewPromotion] = React.useState(false);
    const [initialDataForEditPromotion, setInitialDataForEditPromotion] = React.useState<IPromotionForm | null>(null);
    const [promotionTypes, setPromotionTypes] = React.useState<ITypes[]>([]);
    const [promotionLines, setPromotionLines] = React.useState<IPromoLineItem[]>([]);
    const [allExistingGifts, setAllExistingGifts] = React.useState<IGiftItem[]>([]);
    const [allExistingCashbacks, setAllExistingCashbacks] = React.useState<IGiftItem[]>([]);
    const [purchaseSumsForTypeGift, setPurchaseSumsForTypeGift] = React.useState<ISumItem[]>([]);
    const [purchaseSumsForTypeCashback, setPurchaseSumsForTypeCashback] = React.useState<ISumItem[]>([]);
    const [cashback, setCashback] = React.useState<IGiftItem[]>([]);
    

    return {
        isRefreshRequired,
        setIsRefreshRequired,
        isCreateNewPromotion,
        setIsCreateNewPromotion,
        initialDataForEditPromotion,
        setInitialDataForEditPromotion,
        promotionTypes,
        setPromotionTypes,
        promotionLines,
        setPromotionLines,
        allExistingGifts,
        setAllExistingGifts,
        allExistingCashbacks,
        setAllExistingCashbacks,
        purchaseSumsForTypeGift,
        setPurchaseSumsForTypeGift,
        purchaseSumsForTypeCashback,
        setPurchaseSumsForTypeCashback,
        cashback,
        setCashback
    }
}
const useProvideDemoMaterials = () => {
    const [initialDataForCreateReference, setInitialDataForCreateReference] = React.useState<any>(null);
    const [initialDataForEditReference, setInitialDataForEditReference] = React.useState<any>(null);
    const [groupOptions, setGroupOptions] = React.useState<any[]>([]);
    const [isRefreshRequired, setIsRefreshRequired] = React.useState(false);

    return {
        initialDataForCreateReference,
        initialDataForEditReference,
        groupOptions,
        isRefreshRequired,
        setGroupOptions,
        setInitialDataForCreateReference,
        setInitialDataForEditReference,
        setIsRefreshRequired
    };

};
