import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Typography } from "antd";
import { REQUIRED } from "@utils/validation";
import { FormSelect } from "@components/FormControls";
import styles from "@pages/PromotionSettings/style.module.scss";
import { ISublineItem, PromoLineModalProps } from "@hooks/interfaces";
import { useForm } from "antd/lib/form/Form";
import { usePromotions } from "@hooks/useContext";
import { DeleteOutlined } from "@ant-design/icons";
import { IsIterableArray, IsNullOrUndefined } from "@utils/helpers";
import { usePromotionsAPI } from "@api/promotions";
import { useNotification } from "@hooks/UI/useNotification";

export const EditPromoLineForm: React.FC<PromoLineModalProps> = ({ onClose, initialFormValues, refresh }) => {
    const api = usePromotionsAPI();
    const { UseDefaultNotification } = useNotification();
    const { promotionLines } = usePromotions();
    const [form] = useForm();
    const [sublines, setSublines] = useState<ISublineItem[]>([]);
    const [deletedSublineId, setDeletedSublineId] = React.useState<number[]>([]);

    const promoLineOptions = useMemo(() => promotionLines?.map((item) => ({
        value: item.id,
        label: item.name
    })), [promotionLines]);

    useEffect(() => {
        if(!IsNullOrUndefined(initialFormValues)) {
            const parsedData = {
                promotionalLineId: initialFormValues?.id,
                name: ""
            }
            form.setFieldsValue(parsedData);
            setSublines(initialFormValues?.promotionalSublines ?? [])
        }
    }, [initialFormValues]);

    const handleDeleteClick = (id: number) => {
        setDeletedSublineId((prev) => [...prev, id]);
        const newSublines =[...sublines]?.filter((sub) => sub.id !== id);
        setSublines(newSublines)
    }

    const handleSubmit = async () => {
        if(IsIterableArray(deletedSublineId)) {
            const requests = deletedSublineId.map((id) => api.deleteSubline(id))
            await Promise.all(requests);
            UseDefaultNotification("success", {
                message: "Успешно",
                description: "Линейка успешно обновлена",
                duration: 3
            })
            refresh();
            onClose();
        }
    };

    return (
        <Modal width={1000}
               visible={true}
               onCancel={onClose}
               footer={false}>
            <Typography.Title>Линейка {initialFormValues?.name}</Typography.Title>

            <Form form={form} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item name="promotionalLineId" rules={[REQUIRED]} label={"Для линейки"}>
                            <FormSelect disabled options={promoLineOptions} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Title level={2}>Текущие сублинейки:</Typography.Title>
                        {sublines?.map((subline) => (
                            <div key={subline.id} className={styles.sublineItem}>
                                <Typography.Title  level={5}>{subline.name}</Typography.Title>
                                <DeleteOutlined onClick={() => handleDeleteClick(subline.id)} className={styles.deleteIcon} />
                            </div>

                        ))}
                    </Col>
                </Row>

                <Row gutter={30}>
                    <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                    <Col span={12}><Button onClick={handleSubmit}
                                           className={styles.createButton}>Сохранить</Button></Col>
                </Row>
            </Form>
        </Modal>
    );
};