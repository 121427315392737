import React from "react";
import { Button, Modal, Typography } from "antd";

type ConfirmModalProps = {
    visible: boolean;
    title: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
    const { visible,  title = "Вы уверены?", confirmButtonText = "Да", cancelButtonText = "Отмена", onConfirm, onCancel } = props;
    return (
        <Modal  visible={visible}  onCancel={onCancel} footer={false}>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
                <Button style={{width: "100%", height: 62, borderRadius: 8}} onClick={onCancel}>{cancelButtonText}</Button>
                <Button  style={{width: "100%", background: "#3DCD58", height: 62, borderRadius: 8}} onClick={onConfirm}>{confirmButtonText}</Button>
            </div>

        </Modal>
    );
};
