import React from "react";
import { AdminLayout } from "@components/Layout";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { ProvideAppContext } from "@hooks/useContext";
import {ConfigProvider} from "antd";
import locale from 'antd/es/locale/ru_RU'

export const App = () => {
    return (
        <ConfigProvider locale={locale}>
            <ProvideAppContext>
                <BrowserRouter>
                    <AdminLayout />
                </BrowserRouter>
            </ProvideAppContext>
        </ConfigProvider>

    );
};