import { DemoMaterialsListResponse, IReferenceFormItem } from "@hooks/interfaces";
import axios from "@axios";
import { useState } from "react";

export const useDemoMaterialsAPI = () => {
    const [loading, setLoading] = useState(false);

    const getDemoMaterialsList  = async () => {
        setLoading(true);
            try {
                return await axios.get("/reference") as DemoMaterialsListResponse
            } catch(err) {
                console.log({err});
            } finally {
                setLoading(false);
            }
    }

    const createDemoMaterial = async (payload: IReferenceFormItem) => {
        setLoading(true);
        try {
            return await axios.post("/reference ", payload);
        } catch(err) {
            console.log({err});
        } finally {
            setLoading(false);
        }
    }
    // принято решение заблокировать функционал редактирования референса
    // const updateDemoMaterial = async (payload: IReferenceFormItem) => {
    //     setLoading(true);
    //     try {
    //         return await axios.put(`/reference/${payload.id}`, payload);
    //     } catch(err) {
    //         console.log({err});
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const deleteDemoMaterial = async (id: string | number) => {
        setLoading(true);
        try {
            return await axios.delete(`/reference/${id}`);
        } catch(err) {
            console.log({err});
        } finally {
            setLoading(false);
        }
    }

    return {getDemoMaterialsList , createDemoMaterial, deleteDemoMaterial, loading};
}