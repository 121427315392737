import React from "react";
import { useDemoMaterials } from "@hooks/useContext";
import styles from "../../style.module.scss";
import { IReferenceFormItem } from "@hooks/interfaces";

const initialFormValues: IReferenceFormItem = {
    name: '',
    description: '',
    referenceGroupId : null
}

export const Header = () => {
    const { setInitialDataForCreateReference } = useDemoMaterials();

    return (
        <div className={styles.header}>
            <div />
            <button onClick={() => setInitialDataForCreateReference({...initialFormValues})} className={styles.addButton}>Добавить +
            </button>
        </div>

    );
};
