import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Radio, Row, Typography } from "antd";
import { GiftFormModalProps, PromoTypes } from "@hooks/interfaces";
import { ONLY_NUMBERS, REQUIRED } from "@utils/validation";
import { FormMultiSelect, FormSelect, FormTextInput } from "@components/FormControls";
import styles from "@pages/PromotionSettings/style.module.scss";
import { useForm } from "antd/lib/form/Form";
import { usePromotionsAPI } from "@api/promotions";
import { useNotification } from "@hooks/UI/useNotification";
import { usePromotions } from "@hooks/useContext";
import { IsNullOrUndefined } from "@utils/helpers";


export const EditGiftForm: React.FC<GiftFormModalProps> = ({onClose, initialFormValues, refresh}) => {
    const api = usePromotionsAPI();
    const { UseDefaultNotification } = useNotification();
    const { allExistingGifts, allExistingCashbacks } = usePromotions();
    const [form] = useForm();
    const type = initialFormValues?.isCashback ? PromoTypes.Cashback : PromoTypes.Gift;

    const handleSubmit = async () => {
        await form.validateFields();
        const formValues = form.getFieldsValue();
        const isCashback = formValues?.type === PromoTypes.Cashback;
        const payload = {
            amount: formValues?.amount,
            gifts: isCashback ? [formValues?.gifts] : formValues?.gifts,
            id: initialFormValues!.id,
            isCashback
        }

        await api.updatePurchaseSum(payload)
        UseDefaultNotification("success", {
            message: "Успешно",
            description:"Сумма покупки успешно обновлена",
            duration: 3
        })
        refresh();
        onClose();
    }

    const giftOptions = useMemo(() => {
        switch (type) {
            case PromoTypes.Gift:
                return allExistingGifts?.map((item) => ({ value: item.id, label: item.name })) || [];
            case PromoTypes.Cashback:
                return allExistingCashbacks?.map((item) => ({ value: item.id, label: item.price })) || [];
            default:
                return [];
        }
    }, [allExistingGifts, allExistingCashbacks, type]);

    useEffect(() => {
        if(!IsNullOrUndefined(initialFormValues)) {
            const parsedData = {
                amount: initialFormValues?.amount,
                type,
                gifts: initialFormValues?.gifts?.map((g) => g.id)
            }
            form.setFieldsValue(parsedData);
        }
    }, [initialFormValues, type]);


    return (
        <Modal width={1000}
               visible={true}
               onCancel={onClose}
               footer={false}>
            <Typography.Title>Подарок</Typography.Title>
            <Form form={form} layout="vertical">
                <Row>
                    <Form.Item name="type" rules={[REQUIRED]} label={"Тип"}>
                        <Radio.Group disabled>
                            <Radio value={PromoTypes.Gift}>Подарок</Radio>
                            <Radio value={PromoTypes.Cashback}>Кэшбэк</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="amount" rules={[REQUIRED, ONLY_NUMBERS]} label={"Cумма покупки"}>
                            <FormTextInput disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {type === PromoTypes.Gift && (
                            <Form.Item name="gifts" rules={[REQUIRED]} label={"Подарки"}>
                                <FormMultiSelect options={giftOptions} maxTagCount={5} />
                            </Form.Item>
                        )}
                        {type === PromoTypes.Cashback && (
                            <Form.Item name="gifts" rules={[REQUIRED]} label={"Кэшбэк"}>
                                <FormSelect options={giftOptions} />
                            </Form.Item>
                        )}
                    </Col>
                </Row>

                <Row gutter={30}>
                    <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                    <Col span={12}><Button onClick={handleSubmit}
                                           className={styles.createButton}>Сохранить</Button></Col>
                </Row>
            </Form>
        </Modal>
    );
};