import React, { useEffect, useState } from "react";
import {
    CreatePromoLineForm,
    EditPromoLineForm,
    PromoLinesTabHeader,
    PromoLinesTable
} from "@pages/PromotionSettings/components";
import styles from "../style.module.scss";
import { usePromotionsAPI } from "@api/promotions";
import { IPromoLineItem } from "@hooks/interfaces";
import { usePromotions } from "@hooks/useContext";
import { IsNullOrUndefined } from "@utils/helpers";

export const PromoLinesTab = () => {
    const api = usePromotionsAPI();
    const {setPromotionLines} = usePromotions();
    const [isRefreshRequired, setIsRefreshRequired] = useState(false);
    const [isCreatePromoLine, setIsCreatePromoLine] = useState(false);
    const [initialDataForEditPromoLine, setInitialDataForEditPromoLine] = useState<any>(null);
    const [data, setData] = useState<IPromoLineItem[]>([])

    const getPromoLines = async () => {
        setIsRefreshRequired(false);
        const response = await api.getPromoLinesWithSublines();
        setData(response?.data?.map((item: IPromoLineItem) => ({...item, key: item.id})) ?? []);
        setPromotionLines(response?.data ?? []);
    }

    useEffect(() => {
        getPromoLines().then();
    }, []);

    useEffect(() => {
        if(isRefreshRequired) {
            getPromoLines().then();
        }
    }, [isRefreshRequired]);

    return (
        <div className={styles.moduleRoot}>
            <PromoLinesTabHeader onCreateClick={() => setIsCreatePromoLine(true)}/>
            <PromoLinesTable data={data} loading={api.loading} onRowClick={setInitialDataForEditPromoLine} />
            {isCreatePromoLine && <CreatePromoLineForm onClose={() => setIsCreatePromoLine(false)} refresh={() => setIsRefreshRequired(true)} />}
            {!IsNullOrUndefined(initialDataForEditPromoLine) && <EditPromoLineForm onClose={() => setInitialDataForEditPromoLine(null)} initialFormValues={initialDataForEditPromoLine} refresh={() => setIsRefreshRequired(true)}/>}
        </div>
    );
};
