import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Typography } from "antd";
import { IPromotionForm, PromotionModalFormProps, PromoTypes } from "@hooks/interfaces";
import { ONLY_NUMBERS, REQUIRED } from "@utils/validation";
import { FormMultiSelect, FormRangePicker, FormSelect, FormTextInput } from "@components/FormControls";
import styles from "@pages/Promotions/style.module.scss";
import { getPromotionBody, IsIterableArray, IsNullOrUndefined } from "@utils/helpers";
import { useForm } from "antd/lib/form/Form";
import { usePromotionPickerOptions } from "@hooks/usePromotionPickerOptions";
import { useNotification } from "@hooks/UI/useNotification";
import { usePromotionsAPI } from "@api/promotions";

export const EditPromotionForm: React.FC<PromotionModalFormProps> = ({ onClose, initialFormValues = null, refresh }
) => {
    const { UseDefaultNotification } = useNotification();
    const { updatePromotion } = usePromotionsAPI();
    const [form] = useForm();
    const type = Form.useWatch("stockTypeId", form);
    const mainPromoLineValues: number[] = Form.useWatch("promotionalMainLine", form) || [];
    const purchaseSumValue = Form.useWatch("purchaseAmountId", form);
    const {
        promoTypesOptions,
        promoLinesOptions,
        promoSublineOptions,
        sumOptions,
        giftsOptionForTypeGift,
        cashbackOptions,
        getPriceString
    } = usePromotionPickerOptions({ type, mainPromoLineValues, purchaseSumValue });

    useEffect(() => {
        if (!IsNullOrUndefined(initialFormValues)) {
            form.setFieldsValue(initialFormValues);
            form.setFieldsValue({ id: !initialFormValues?.id });
        }
    }, [initialFormValues])

    const handleGiftsChange = (values: number[]) => {
        form.setFieldsValue({gifts: values});
        const price = getPriceString(values)
        form.setFieldsValue({giftPrice: price});
    }


    function RenderFormFields() {
        switch (type) {
            case PromoTypes.Gift:
                return (
                    <>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"period"} label={"Период:"} rules={[REQUIRED]}>
                                    <FormRangePicker size={"large"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalMainLine" rules={[REQUIRED]} label={"Линейка"}>
                                    <FormMultiSelect
                                        options={promoLinesOptions}
                                        maxTagCount={5}
                                        onChange={(value) => {
                                            if (!IsIterableArray(value)) {
                                                form.setFieldsValue({ promotionalSublines: [] });
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalSublines" rules={[REQUIRED]} label={"Сублинейка"}>
                                    <FormMultiSelect
                                        options={promoSublineOptions}
                                        maxTagCount={5}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item name="purchaseAmountId" rules={[REQUIRED]} label={"Сумма покупки"}>
                                    <FormSelect
                                        options={sumOptions}
                                        onChange={() => form.setFieldsValue({ gifts: [] })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="gifts" rules={[REQUIRED]} label={"Подарок"}>
                                    <FormMultiSelect
                                        options={giftsOptionForTypeGift}
                                        onChange={handleGiftsChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="giftPrice" rules={[REQUIRED]} label={"Стоимость подарка"}>
                                    <FormTextInput disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>

                );
            case PromoTypes.Cashback:
                return (
                    <>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"period"} label={"Период:"} rules={[REQUIRED]}>
                                    <FormRangePicker size={"large"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalMainLine" rules={[REQUIRED]} label={"Линейка"}>
                                    <FormMultiSelect
                                        options={promoLinesOptions}
                                        maxTagCount={5}
                                        onChange={(value) => {
                                            if (!IsIterableArray(value)) {
                                                form.setFieldsValue({ promotionalSublines: [] });
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalSublines" rules={[REQUIRED]} label={"Сублинейка"}>
                                    <FormMultiSelect
                                        options={promoSublineOptions}
                                        maxTagCount={5}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item name="purchaseAmountId" rules={[REQUIRED]} label={"Сумма покупки"}>
                                    <FormSelect
                                        options={sumOptions}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="gifts" rules={[REQUIRED, ONLY_NUMBERS]} label={"Кэшбэк"}>
                                    <FormSelect
                                        options={cashbackOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            case PromoTypes.N_Plus_1:
                return (
                    <>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"period"} label={"Период:"} rules={[REQUIRED]}>
                                    <FormRangePicker size={"large"} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalMainLine" rules={[REQUIRED]} label={"Линейка"}>
                                    <FormMultiSelect
                                        options={promoLinesOptions}
                                        maxTagCount={5}
                                        onChange={(value) => {
                                            if (!IsIterableArray(value)) {
                                                form.setFieldsValue({ promotionalSublines: [] });
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item name="promotionalSublines" rules={[REQUIRED]} label={"Сублинейка"}>
                                    <FormMultiSelect
                                        options={promoSublineOptions}
                                        maxTagCount={5}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={10}>
                            <Col span={8}>
                                <Form.Item name="samples" rules={[REQUIRED]} label={"Референс"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="sampleCount" rules={[REQUIRED, ONLY_NUMBERS]}
                                           label={"Количество изделий"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="samplePrice" rules={[REQUIRED, ONLY_NUMBERS]}
                                           label={"Стоимость"}>
                                    <FormTextInput />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            default:
                return null;
        }
    }

    const handleSubmit = async () => {
        await form.validateFields();
        const formValues = form.getFieldsValue();
        const body = getPromotionBody(formValues);
        const payload = { ...body, id: initialFormValues!.id };

        try {
            await updatePromotion(payload as unknown as IPromotionForm);
            UseDefaultNotification("success", {
                message: "Успешно",
                description: "Акция успешно обновлена",
                duration: 3
            });

        } catch (err) {
            console.log({ err });
        } finally {
            onClose();
            refresh();
        }
    };


    return (
        <Modal width={1000}
               visible={true}
               onCancel={onClose}
               footer={false}>
            <Typography.Title>Акция {initialFormValues!.id} </Typography.Title>
            <Form form={form} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item name="stockTypeId" rules={[REQUIRED]} label={"Тип акции"}>
                            <FormSelect
                                options={promoTypesOptions}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {RenderFormFields()}

                <Row gutter={30}>
                    <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                    <Col span={12}><Button onClick={handleSubmit}
                                           className={styles.createButton}>Сохранить</Button></Col>
                </Row>
            </Form>
        </Modal>
    );
};

