import React from "react";
import {Menu} from "antd";
import './promotion-setting-tabs.scss'
import { PromotionSettingModules } from "@hooks/interfaces";

const menuItems = [
    {key: 0, label: "Акционные линейки"},
    {key: 1, label: " Подарок/Кэшбек"},
]

type PromotionSettingTabsProps = {
    onClick: React.Dispatch<React.SetStateAction<number>>
}

export const PromotionSettingTabs: React.FC<PromotionSettingTabsProps> = ({onClick}) => {
    return (
        <div className={'promotion-setting-tabs-container'}>
            <Menu mode="horizontal" defaultSelectedKeys={[`${PromotionSettingModules.PromoLines}`]} style={{ backgroundColor: "transparent" }}>
                {menuItems.map((item) => (
                    <Menu.Item key={item.key} onClick={() => onClick(item.key)}>
                        <h2>{item.label}</h2>
                    </Menu.Item>

                ))}
            </Menu>
        </div>
    );
};

export default PromotionSettingTabs;