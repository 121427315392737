import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { REQUIRED } from "@utils/validation";
import { FormSelect, FormTextInput } from "@components/FormControls";
import styles from "@pages/PromotionSettings/style.module.scss";
import { PromoLineModalProps } from "@hooks/interfaces";
import { useForm } from "antd/lib/form/Form";
import { useNotification } from "@hooks/UI/useNotification";
import { IsIterableArray } from "@utils/helpers";
import { usePromotions } from "@hooks/useContext";
import { usePromotionsAPI } from "@api/promotions";

export const CreatePromoLineForm: React.FC<PromoLineModalProps> = ({ onClose, refresh }) => {
    const { UseDefaultNotification } = useNotification();
    const api = usePromotionsAPI();
    const { promotionLines } = usePromotions();
    const [form] = useForm();
    const [formForCreate, setFormForCreate] = React.useState({ mainLine: false, subline: false });
    const [sublineName, setSublineName] = React.useState("");
    const [sublines, setSublines] = React.useState<string[]>([]);

    const promoLineOptions = useMemo(() => promotionLines?.map((item) => ({
        value: item.id,
        label: item.name
    })), [promotionLines]);
    const getTitle = () => {
        if (!formForCreate.mainLine && !formForCreate.subline) return "Новая линейка / сублинейка";
        if (formForCreate.mainLine) return "Новая линейка + сублинейка (необязательно)";
        if (formForCreate.subline) return "Новая сублинейка";
    };

    const handleAddSubline = () => {
        if (!!sublineName) {
            setSublines((prev) => [...prev, sublineName]);
            setSublineName("");
            form.setFieldsValue({ sublineName: "" });
        } else {
            UseDefaultNotification("warning", {
                message: "Внимание",
                description: "Заполните название сублинейки",
                duration: 3
            });
        }
    };
    useEffect(() => {
        if (IsIterableArray(sublines)) {
            const parsedData = sublines.join(", ");
            form.setFieldsValue({ sublines: parsedData });
        }
    }, [sublines]);


    const handleSubmitMainLine = async () => {
        await form.validateFields();
        const formValues = await form.getFieldsValue();
        const payload = IsIterableArray(sublines) ? {
            name: formValues?.name,
            sublines
        } : { name: formValues?.name };
        await api.createMainLine(payload);
        UseDefaultNotification("success", {
            message: "Успешно",
            description: "Акционная линейка успешно добавлена",
            duration: 3
        })
        refresh();
        onClose();
    };

    const handleSubmitSubline = async () => {
        await form.validateFields();
        const formValues = await form.getFieldsValue();
        console.log({ formValues });
        const payload = {
            name: formValues?.name,
            promotionalLineId: formValues?.promotionalLineId,
        }

        await api.createSubLine(payload);
        UseDefaultNotification("success", {
            message: "Успешно",
            description: "Cублинейка успешно добавлена",
            duration: 3
        })
        refresh();
        onClose();
    };
    return (
        <Modal width={1000}
               visible={true}
               onCancel={onClose}
               footer={false}>
            <Typography.Title>{getTitle()}</Typography.Title>

            {(!formForCreate.mainLine && !formForCreate.subline) && (
                <Row gutter={30}>
                    <Col span={12}>
                        <Button className={styles.optionButton}
                                onClick={() => setFormForCreate({ mainLine: true, subline: false })}>Создать новую
                            линейку и
                            сублинейку</Button>
                    </Col>
                    <Col span={12}>
                        <Button className={styles.optionButton}
                                onClick={() => setFormForCreate({ mainLine: false, subline: true })}>Создать новую
                            сублинейку</Button>
                    </Col>
                </Row>
            )}

            {formForCreate.mainLine && (
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item name="name" rules={[REQUIRED]} label={"Название линейки"}>
                                <FormTextInput />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item name="sublines" label={"Cублинейки"}>
                                <FormTextInput disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row align="middle" gutter={30}>
                        <Col span={16}>
                            <Form.Item name="sublineName" label={"Cублинейки"}>
                                <Input value={sublineName} onChange={(e) => setSublineName(e.target.value)}
                                       className={"form-text-input"} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button className={styles.addSublineButton} onClick={handleAddSubline}>
                                Добавить
                            </Button>

                        </Col>
                    </Row>

                    <Row gutter={30}>
                        <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                        <Col span={12}><Button onClick={handleSubmitMainLine}
                                               className={styles.createButton}>Сохранить</Button></Col>
                    </Row>
                </Form>
            )}

            {formForCreate.subline && (
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item name="promotionalLineId" rules={[REQUIRED]} label={"Для линейки"}>
                                <FormSelect options={promoLineOptions} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                                <FormTextInput />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={30}>
                        <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                        <Col span={12}><Button onClick={handleSubmitSubline}
                                               className={styles.createButton}>Сохранить</Button></Col>
                    </Row>
                </Form>
            )}
        </Modal>
    );
};