import React from "react";
import styles from '../../style.module.scss'

type PromoLinesTabHeaderProps = {
    onCreateClick: () => void;
}
export const PromoLinesTabHeader: React.FC<PromoLinesTabHeaderProps> = ({onCreateClick}) => {
    return (
        <div className={styles.tabHeader}>
            <div/>
            <button onClick={onCreateClick} className={styles.addButton}>Добавить +</button>
        </div>
    );
};
