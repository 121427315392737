import React from "react";
import { Table } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import styles from "../../style.module.scss";
import { useDemoMaterials } from "@hooks/useContext";
import { ITableItem } from "@hooks/interfaces";
import { ConfirmModal } from "@components/ConfirmModal/ConfirmModal";
import { useNotification } from "@hooks/UI/useNotification";
import { useDemoMaterialsAPI } from "@api/demomaterials";

const { Column } = Table;

type MaterialsTableProps = {
    loading: boolean;
    data: ITableItem[]
}

export const MaterialsTable: React.FC<MaterialsTableProps> = ({ data, loading }) => {
    const { setInitialDataForEditReference, setIsRefreshRequired } = useDemoMaterials();
    const {UseDefaultNotification} = useNotification();
    const {deleteDemoMaterial} = useDemoMaterialsAPI();

    const [open, setOpen] = React.useState(false);
    const [selectedReference, setSelectedReference] = React.useState<ITableItem | null>(null);
    const toggleConfirmDeleteModal = () => setOpen((prev) => !prev);


    const handleDeleteReference = async () => {
        try {
            await deleteDemoMaterial(selectedReference?.id as number);
            UseDefaultNotification("success", {
                message: "Успешно",
                description: "Референс удален",
                duration: 5
            });
        } catch (err) {
            console.error(err);
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: "Ошибка при удалении референса.",
                duration: 5
            });
        } finally {
            setIsRefreshRequired(true);
            setSelectedReference(null);
            toggleConfirmDeleteModal();
        }
    };


    return (
        <div className={styles.tableWrapper}>
            <Table dataSource={data} loading={loading}>
                <Column title={"Группа"} dataIndex={"groupName"}></Column>
                <Column title={"Название"} dataIndex={"name"}></Column>
                <Column title={"Описание"} dataIndex={"description"}></Column>
                <Column
                    title={"Действия"}
                    key={"action"}
                    render={(_: any, record: ITableItem) => (
                        <div className={styles.icons}>
                            <EyeOutlined className={styles.editIcon} onClick={() => setInitialDataForEditReference({
                                referenceGroupId: record.referenceGroupId,
                                id: record.id,
                                name: record.name,
                                description: record.description
                            })} />
                            <DeleteOutlined className={styles.deleteIcon} onClick={() => {
                                setSelectedReference(record);
                                toggleConfirmDeleteModal();
                            }} />

                        </div>
                    )}
                />
            </Table>

            {open && <ConfirmModal visible={open} title={"Вы уверены, что хотите удалить референс?"} onCancel={() => {
                setSelectedReference(null);
                toggleConfirmDeleteModal();
            }} onConfirm={handleDeleteReference} />}
        </div>
    );
};
