import React from "react";
import { Modal, Typography, Form, Row, Col, Button } from "antd";
import { DemoMaterialsModalFormProps } from "@hooks/interfaces";
import { useForm } from "antd/lib/form/Form";
import { REQUIRED } from "@utils/validation";
import { FormSelect, FormTextInput } from "@components/FormControls";
import styles from "@pages/DemoMaterials/style.module.scss";
import { useDemoMaterials } from "@hooks/useContext";
import { useDemoMaterialsAPI } from "@api/demomaterials";
import { useNotification } from "@hooks/UI/useNotification";

export const CreateReferenceForm: React.FC<DemoMaterialsModalFormProps> = ({ onClose }) => {
    const {UseDefaultNotification} = useNotification();
    const [form] = useForm();
    const { groupOptions, setIsRefreshRequired, setInitialDataForCreateReference } = useDemoMaterials();
    const {createDemoMaterial, loading} = useDemoMaterialsAPI();

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue();
        const isValid = Object.keys(formValues).every((key) => !!(formValues[key]));

        if(isValid) {
            try {
                await createDemoMaterial(formValues);
                UseDefaultNotification("success", {
                    message: "Успешно",
                    description: "Новый референс успешно добавлен",
                    duration: 5
                });
            } catch (err) {
                console.error(err);
                UseDefaultNotification("error", {
                    message: "Ошибка",
                    description: "Ошибка при создании референса.",
                    duration: 5
                });
            } finally {
                setIsRefreshRequired(true);
                setInitialDataForCreateReference(null);
            }
        } else {
            UseDefaultNotification("warning", {
                message: "Внимание",
                description: "Убедитесь, что все обязательные поля заполнены",
                duration: 5
            });
        }
    };

    return (
        <Modal
            width={1000}
            visible={true}
            onCancel={onClose}
            footer={false}

        >
            <Typography.Title>Новый референс </Typography.Title>
            <Form form={form} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item name="referenceGroupId" rules={[REQUIRED]} label={"Группа"}>
                            <FormSelect
                                options={groupOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                            <FormTextInput />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item name="description" rules={[REQUIRED]} label={"Описание"}>
                            <FormTextInput />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={30}>
                    <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                    <Col span={12}><Button disabled={loading} loading={loading} onClick={handleSubmit}
                                           className={styles.createButton}>Сохранить</Button></Col>
                </Row>

            </Form>
        </Modal>
    );
};