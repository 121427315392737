import React from "react";
import {PromotionSettingTabs} from "@pages/PromotionSettings/components";
import style from './style.module.scss'
import { PromotionSettingModules } from "@hooks/interfaces";
import {PromoLinesTab} from "@pages/PromotionSettings/tabs/PromoLinesTab";
import {GiftsAndCashbackTab} from "@pages/PromotionSettings/tabs/GiftsAndCashbackTab";

export const PromotionSettings = () => {
    const [activeTab, setActiveTab] = React.useState(PromotionSettingModules.PromoLines)
    return (
        <div className={style.container}>
            <PromotionSettingTabs onClick={setActiveTab}/>
            {activeTab === PromotionSettingModules.PromoLines && <PromoLinesTab  />}
            {activeTab === PromotionSettingModules.GiftsAndCashback && <GiftsAndCashbackTab  />}
        </div>
    );
};