import React, { useState } from "react";
import { Header, PromotionsTable, CreatePromotionForm, EditPromotionForm } from "@pages/Promotions/components";
import styles from "./style.module.scss";
import { usePromotions } from "@hooks/useContext";
import { IsNullOrUndefined } from "@utils/helpers";
import { usePromotionsAPI } from "@api/promotions";
import { IPromotionTableItem } from "@hooks/interfaces";

export const Promotions: React.FC = () => {
    const api = usePromotionsAPI();
    const promotionStore = usePromotions();
    const [data, setData] = useState<IPromotionTableItem[]>([]);

    const getPromotions = async () => {
        const response = await api.getPromotionsList();
        setData(response?.data?.map((item: IPromotionTableItem) => ({...item, key: item.id})) ?? []);
        promotionStore.setIsRefreshRequired(false);
    }


    React.useEffect(() => {
        //api cals to get promotion types , lines, gifts, purchase sums

        const getPromoTypes = async () => {
            const response = await api.getPromoTypes();
            promotionStore.setPromotionTypes(response?.data ?? []);
        }

        const getPromoLines = async () => {
            const response = await api.getPromoLinesWithSublines();
            promotionStore.setPromotionLines(response?.data ?? []);
        }

        const getAllGifts = async () => {
            const response = await api.getAllExistingGifts();
            promotionStore.setAllExistingGifts(response?.data ?? []);
        }

        const getSumsForTypeGift = async () => {
            const response = await api.getSumsWithGiftsForTypeGift();
            promotionStore.setPurchaseSumsForTypeGift(response?.data ?? []);
        }

        const getSumsForTypeCashback = async () => {
            const response = await api.getSumsWithGiftsForTypeCashback();
            promotionStore.setPurchaseSumsForTypeCashback(response?.data ?? []);
        }

        const getCashback = async () => {
            const response = await api.getCashback();
            promotionStore.setCashback(response?.data ?? []);
        }

        getPromotions().then()
        getPromoTypes().then()
        getPromoLines().then()
        getAllGifts().then()
        getSumsForTypeGift().then()
        getSumsForTypeCashback().then()
        getCashback().then()
    }, [])

    React.useEffect(() => {
        if(promotionStore.isRefreshRequired) {
            getPromotions().then()
        }
    }, [promotionStore.isRefreshRequired]);


    return (
        <div className={styles.container}>
            <Header />
            <PromotionsTable data={data} loading={api.loading} />
            {promotionStore.isCreateNewPromotion && <CreatePromotionForm refresh={() => promotionStore.setIsRefreshRequired(true)} onClose={() => promotionStore.setIsCreateNewPromotion(false)} />}
            {!IsNullOrUndefined(promotionStore.initialDataForEditPromotion) && <EditPromotionForm refresh={() => promotionStore.setIsRefreshRequired(true)} initialFormValues={promotionStore.initialDataForEditPromotion} onClose={() => promotionStore.setInitialDataForEditPromotion(null)} />}
        </div>
    );
};