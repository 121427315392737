import React, { useEffect } from "react";
import { Modal, Typography, Form, Row, Col, Button } from "antd";
import { DemoMaterialsModalFormProps } from "@hooks/interfaces";
import { useForm } from "antd/lib/form/Form";
import { REQUIRED } from "@utils/validation";
import { FormSelect, FormTextInput } from "@components/FormControls";
import styles from '@pages/DemoMaterials/style.module.scss'
import { useDemoMaterials } from "@hooks/useContext";

export const EditReferenceForm: React.FC<DemoMaterialsModalFormProps> = ({onClose}) => {
    const [form] = useForm();
    const {initialDataForEditReference, groupOptions } = useDemoMaterials()

    useEffect(() => {
        form.setFieldsValue(initialDataForEditReference)
    }, [])

    return (
        <Modal
            width={1000}
            visible={true}
            onCancel={onClose}
            footer={false}

        >
            <Typography.Title>Референс {initialDataForEditReference?.name} </Typography.Title>
            <Form disabled={true} form={form} layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item name="referenceGroupId" rules={[REQUIRED]} label={"Группа"}>
                            <FormSelect
                                options={groupOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                            <FormTextInput />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item name="description" rules={[REQUIRED]} label={"Описание"}>
                            <FormTextInput />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span={24}><Button className={styles.cancelButton} onClick={onClose}>Закрыть</Button></Col>
            </Row>
        </Modal>
    );
};