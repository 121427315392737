import React from "react";
import { Button, Input, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import styles from "../../style.module.scss";
import { ColumnsType } from "antd/es/table";
import { IsIterableArray, IsNullOrUndefined, parseDate, parsePromotionTableItemToFormValues } from "@utils/helpers";
import { IPromotionTableItem } from "@hooks/interfaces";
import { usePromotions } from "@hooks/useContext";


type PromotionsTableProps = {
    data: IPromotionTableItem[],
    loading: boolean,
}

export const PromotionsTable: React.FC<PromotionsTableProps> = ({ data, loading }) => {
    const { setInitialDataForEditPromotion } = usePromotions();

    const columns: ColumnsType<IPromotionTableItem> = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            onFilter: (value: string | number | boolean, record) => record?.name?.toLowerCase()?.includes(`${value}`.toLowerCase()),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder={"Поиск"}
                            value={IsIterableArray(selectedKeys) ? selectedKeys[0] : ""}
                            onChange={e => setSelectedKeys([e?.target?.value ?? []])}
                            onPressEnter={() => {
                                confirm();
                            }}
                            style={{ marginBottom: 8, display: "block" }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => confirm()}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Применить
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilters?.();
                                    confirm({ closeDropdown: true });
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Очистить
                            </Button>
                        </Space>
                    </div>
                );
            }
        },
        {
            title: "Тип",
            dataIndex: ["type", "name"],
            filters: [
                { text: "N+1", value: "N+1" }
            ],
            onFilter: (value: string | number | boolean, record) => record?.type?.name?.startsWith(value as string),
            key: "type",
            filterSearch: true
        },
        {
            title: "Период",
            dataIndex: "startDate",
            key: "startDate",
            render: (text, record) => {
                return <div>{IsNullOrUndefined(record?.startDate) ? "-" : parseDate(record?.startDate as Date)} - {IsNullOrUndefined(record?.endDate) ? "-" : parseDate(record?.endDate as Date)}</div>;
            }

        },
        {
            title: "Продукты",
            dataIndex: "products",
            key: "products",
            filterSearch: true
        },
        {
            title: "Стоимость подарка",
            dataIndex: "giftPrice",
            key: "giftPrice",
            filterSearch: true
        },
        {
            title: "Бюджет",
            dataIndex: "budget",
            key: "budget",
            filterSearch: true
        },
        {
            title: "Запущена?",
            dataIndex: "isActive",
            key: "isActive",
            filterSearch: true
        },
        {
            title: "Действия",
            render: (_, record: IPromotionTableItem) => (
                <div className={styles.icons}>
                    {record?.isStarted ? <EyeOutlined className={styles.viewIcon} /> : <>
                        <EditOutlined onClick={() => HandleEditClick(record)} className={styles.editIcon} />
                        <DeleteOutlined className={styles.deleteIcon} /></>
                    }
                </div>
            )
        }
    ];

    function HandleEditClick(rowData: IPromotionTableItem) {
        const parsedData = parsePromotionTableItemToFormValues(rowData);
        setInitialDataForEditPromotion(parsedData);
    }

    return (
        <div className={styles.tableWrapper}>
            <Table columns={columns} dataSource={data} loading={loading}  />
        </div>
    );
};
