import React, { useEffect, useMemo } from "react";
import { Button, Col, Form, Modal, Radio, RadioChangeEvent, Row, Typography } from "antd";
import styles from "@pages/PromotionSettings/style.module.scss";
import { ONLY_NUMBERS, REQUIRED } from "@utils/validation";
import { FormMultiSelect, FormSelect, FormTextInput } from "@components/FormControls";
import { useForm } from "antd/lib/form/Form";
import { GiftFormModalProps, PromoTypes } from "@hooks/interfaces";
import { usePromotions } from "@hooks/useContext";
import { usePromotionsAPI } from "@api/promotions";
import { useNotification } from "@hooks/UI/useNotification";


export const CreateGiftForm: React.FC<GiftFormModalProps> = ({ onClose, refresh }) => {
    const api = usePromotionsAPI();
    const { UseDefaultNotification } = useNotification();
    const [form] = useForm();
    const { allExistingGifts, allExistingCashbacks } = usePromotions();
    const [formForCreate, setFormForCreate] = React.useState({ purchaseSum: false, gift: false });
    const type = Form.useWatch("type", form);


    const getTitle = () => {
        if(!formForCreate.purchaseSum && !formForCreate.gift) return "Новая сумма покупки + подарки / Новый подарок"
        if(formForCreate.purchaseSum) return "Новая сумма покупки + подарки"
        if(formForCreate.gift) return "Новый подарок / кэшбэк"
    };

    const giftOptions = useMemo(() => {
        switch (type) {
            case PromoTypes.Gift:
                return allExistingGifts?.map((item) => ({ value: item.id, label: item.name })) || [];
            case PromoTypes.Cashback:
                return allExistingCashbacks?.map((item) => ({ value: item.id, label: item.price })) || [];
            default:
                return [];
        }
    }, [allExistingGifts, allExistingCashbacks, type]);


    useEffect(() => {
        //set default value
        form.setFieldsValue({ type: PromoTypes.Gift });
    }, []);


    const handleSubmitPurchaseSum = async () => {
        await form.validateFields();
        const formValues = form.getFieldsValue();
        const isCashback = formValues?.type === PromoTypes.Cashback;
        const payload = {
            amount: formValues?.amount,
            gifts: isCashback ? [formValues?.gifts] : formValues?.gifts,
            isCashback
        }

        await api.createPurchaseSum(payload)
        UseDefaultNotification("success", {
            message: "Успешно",
            description:"Новая сумма покупки успешно добавлена",
            duration: 3
        })
        refresh();
        onClose();
    };

    const handleSubmitGift = async () => {
        await form.validateFields();
        const formValues = form.getFieldsValue();
        const payload = {
            name: formValues?.name,
            price: formValues?.price,
            isCashback: formValues?.type === PromoTypes.Cashback,
        }
        await api.createGift(payload);
        UseDefaultNotification("success", {
            message: "Успешно",
            description:"Новый подарок успешно добавлен",
            duration: 3
        })
        refresh();
        onClose();
    };

    const handleTypeChange = (e: RadioChangeEvent) => {
        form.resetFields();
        form.setFieldsValue({type: e?.target?.value});
    }

    return (
        <Modal width={1000}
               visible={true}
               onCancel={onClose}
               footer={false}>
            <Typography.Title>{getTitle()}</Typography.Title>
            {(!formForCreate.purchaseSum && !formForCreate.gift) && (
                <Row gutter={30}>
                    <Col span={12}>
                        <Button className={styles.optionButton}
                                onClick={() => setFormForCreate({ purchaseSum: true, gift: false })}>Создать сумму
                            покупки + подарки</Button>
                    </Col>
                    <Col span={12}>

                        <Button className={styles.optionButton}
                                onClick={() => setFormForCreate({ purchaseSum: false, gift: true })}>Создать
                            подарок</Button>
                    </Col>

                </Row>
            )}

            {formForCreate.purchaseSum && (
                <Form form={form} layout="vertical">
                    <Row>
                        <Form.Item name="type" rules={[REQUIRED]} label={"Тип"}>
                            <Radio.Group onChange={handleTypeChange}>
                                <Radio value={PromoTypes.Gift}>Подарок</Radio>
                                <Radio value={PromoTypes.Cashback}>Кэшбэк</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="amount" rules={[REQUIRED, ONLY_NUMBERS]} label={"Cумма покупки"}>
                                <FormTextInput />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {type === PromoTypes.Gift && (
                                <Form.Item name="gifts" rules={[REQUIRED]} label={"Подарки"}>
                                    <FormMultiSelect options={giftOptions} maxTagCount={5}/>
                                </Form.Item>
                            )}
                            {type === PromoTypes.Cashback && (
                                <Form.Item name="gifts" rules={[REQUIRED]} label={"Кэшбэк"}>
                                    <FormSelect options={giftOptions} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={30}>
                        <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                        <Col span={12}><Button onClick={handleSubmitPurchaseSum}
                                               className={styles.createButton}>Сохранить</Button></Col>
                    </Row>
                </Form>
            )}

            {formForCreate.gift && (
                <Form form={form} layout="vertical">
                    <Row>
                        <Form.Item name="type" rules={[REQUIRED]} label={"Тип"}>
                            <Radio.Group>
                                <Radio value={PromoTypes.Gift}>Подарок</Radio>
                                <Radio value={PromoTypes.Cashback}>Кэшбэк</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="name" rules={[REQUIRED]} label={"Название"}>
                                <FormTextInput />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="price" rules={[REQUIRED, ONLY_NUMBERS]} label={"Стоимость"}>
                                <FormTextInput />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col span={12}><Button className={styles.cancelButton} onClick={onClose}>Отмена</Button></Col>
                        <Col span={12}><Button onClick={handleSubmitGift}
                                               className={styles.createButton}>Сохранить</Button></Col>
                    </Row>
                </Form>
            )}
        </Modal>
    );
};
