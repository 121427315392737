import React from "react";
import styles from '../../style.module.scss'


export const GiftsTabHeader: React.FC<{onCreateClick: () => void}> = ({onCreateClick}) => {
    return (
        <div className={styles.tabHeader}>
            <div />
            <button onClick={onCreateClick} className={styles.addButton}>Добавить +</button>
        </div>
    );
};
