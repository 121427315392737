import React, { FC } from "react";
import { SelectProps } from "antd/lib/select";
import { DatePicker, Input, Select, InputNumber  } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { InputProps } from "antd/es/input";
import { InputNumberProps } from "antd/lib/input-number";
import './form-controls.scss'

const { Option } = Select;

export const FormTextInput: FC<InputProps> = (props) => {
    return (
        <Input className={'form-text-input'} {...props} />
    );
};

export const FormNumberInput: FC<InputNumberProps> = (props) => {
    return <InputNumber {...props} />;
}

export const FormSelect: FC<SelectProps> = (props) => {
    const { value, onChange, options, ...rest } = props;
    return (
        <Select
            className={'form-custom-select'}
            value={value}
            onChange={onChange}
            {...rest}>
            {[...options ?? []].map(o => (
                <Option key={o.value} value={o.value}>{o.label}</Option>)
            )}
        </Select>
    );
};

export const FormMultiSelect: FC<SelectProps> = (props) => {
    const { value, onChange, options, ...rest } = props;
    return (
        <Select
            className={'form-custom-select'}
            mode={"multiple"}
            value={value}
            onChange={onChange}
            tagRender={(props) => {
                return <div className={'multi-select-custom-tag'}>{props.label}</div>;
            }}
            {...rest}>
            {[...options ?? []].map(o => (
                <Option key={o.value} value={o.value}>{o.label}</Option>)
            )}
        </Select>
    );
};

export const FormRangePicker = (props: RangePickerProps) => {
    //@ts-ignore
    return <DatePicker.RangePicker {...props} style={{width:'100%'}}/>;
};