import React from "react";
import styles from "../../style.module.scss";
import { usePromotions } from "@hooks/useContext";
import {Link} from "react-router-dom";

export const Header = () => {
    const {setIsCreateNewPromotion} = usePromotions();
    return (
        <div className={styles.header}>
            <Link className={styles.optionsButton} to={'/promotion-settings'}>Расширение опций</Link>
            <button onClick={() => setIsCreateNewPromotion(true)} className={styles.addButton}>Добавить +</button>
        </div>
    );
};
