import { format } from "date-fns";
import FileSaver from "file-saver";
import { TimeZones } from "@pages/User/constants";
import { IPromotionForm, IPromotionTableItem, PromoTypes } from "@hooks/interfaces";
import moment from "moment";


export const parseDate = (date: Date): string => {
    return format(new Date(date), "dd-MM-yyyy");
};

export const reduceString = (text: string, max: number, count: number): string => {
    if (text.length > max) {
        return text.slice(0, count) + "...";
    } else {
        return text;
    }
};

export const parseResponseAsFile = (excelBuffer: any, fileName: string, startDate: Date | null = null, endDate: Date | null = null) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const data = new Blob([excelBuffer], { type: fileType });
    const fullFileName = `${fileName} ${getFileDate(startDate, endDate)}`;
    FileSaver.saveAs(data, fullFileName + fileExtension);
};

const getFileDate = (startDate: Date | null, endDate: Date | null) => {
    return startDate && endDate ?
        `от ${format(startDate, "dd.MM.yyyy")} до ${format(endDate, "dd.MM.yyyy")}` :
        `от ${format(new Date(), "dd.MM.yyyy")}`;
}

export const getProp = (object?: any, keys?: any, defaultVal?: any): any => {
    keys = Array.isArray(keys) ? keys : keys.split(".");
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1));
    }
    return object === undefined ? defaultVal : object;
};

const arrToObject = (arr: string[][]) => {
    const obj = {} as { [key: string]: any };
    for (const [key, value] of arr) {
        obj[key] = value;
    }
    return obj;
};

export const stringifyFilterToQuery = (filter: object) => {
    let query = "";
    const queryArray = Object.entries(filter).flat();
    for (let i = 0; i < queryArray.length; i += 2) {
        if (i === 0) {
            query += `?${queryArray[i]}=${queryArray[i + 1]}`;
        } else {
            query += `&${queryArray[i]}=${queryArray[i + 1]}`;
        }
    }
    return query;
};

export const parseFilter = (query: string) => {
    return arrToObject(query.slice(1).split("&").map((item: string) => item.split("=")));
};

export const parseIanaCode = (ianaCode: string) => TimeZones[ianaCode as keyof typeof TimeZones];

export const IsNullOrUndefined = (value: any) => value === null || value === undefined;

export const IsIterableArray = (array: any) => Array.isArray(array) && !!array.length;

export const getPromotionBody = (formValues: IPromotionForm) => {
    const {stockTypeId} = formValues || {};
    switch (stockTypeId) {
        case PromoTypes.Gift: return {
            name: formValues.name,
            stockTypeId,
            startDate: parseDate(formValues.period[0]),
            endDate: parseDate(formValues.period[1]),
            purchaseAmountId: formValues.purchaseAmountId,
            promotionalSublines: formValues.promotionalSublines,
            gifts: formValues.gifts,
        }

        case PromoTypes.Cashback: return {
            name: formValues.name,
            stockTypeId,
            purchaseAmountId: formValues.purchaseAmountId,
            startDate: parseDate(formValues.period[0]),
            endDate: parseDate(formValues.period[1]),
            promotionalSublines: formValues.promotionalSublines,
            gifts: [formValues.gifts],
        }
        case PromoTypes.N_Plus_1: return {
            name: formValues.name,
            stockTypeId,
            startDate: parseDate(formValues.period[0]),
            endDate: parseDate(formValues.period[1]),
            sampleCount: formValues.sampleCount,
            samplePrice: formValues.samplePrice,
            promotionalSublines: formValues.promotionalSublines,
            samples: formValues.samples?.split(','),
        }

        default: return null

    }
}

export const parsePromotionTableItemToFormValues = (tableItem: IPromotionTableItem) => {
    const type = tableItem.type.id;
    switch (type) {
        case PromoTypes.Gift: return {
            id: tableItem.id,
            name: tableItem.name,
            stockTypeId: type,
            period: [moment(tableItem?.startDate as Date), moment(tableItem?.endDate as Date)],
            purchaseAmountId: tableItem.purchaseAmount?.id,
            promotionalMainLine: tableItem?.promotionalSublines?.map((subline) => subline?.promotionalLineId)?.filter((id, index, self) => self.indexOf(id) === index) || [],
            promotionalSublines: tableItem?.promotionalSublines?.map((subline) => subline.id) || [],
            gifts: tableItem.gifts?.map((gift) => gift?.id) || [],
            giftPrice: tableItem?.gifts?.map((gift) => gift?.price)?.join(',') || '',
        }

        case PromoTypes.Cashback: return {
            id: tableItem.id,
            name: tableItem.name,
            stockTypeId: type,
            period: [moment(tableItem?.startDate as Date), moment(tableItem?.endDate as Date)],
            purchaseAmountId: tableItem.purchaseAmount?.id,
            promotionalMainLine: tableItem?.promotionalSublines?.map((subline) => subline?.promotionalLineId)?.filter((id, index, self) => self.indexOf(id) === index) || [],
            promotionalSublines: tableItem?.promotionalSublines?.map((subline) => subline.id) || [],
            gifts: tableItem.gifts?.map((gift) => gift?.id) || [],
        }

        case PromoTypes.N_Plus_1: return {
            id: tableItem.id,
            name: tableItem.name,
            stockTypeId: type,
            period: [moment(tableItem?.startDate as Date),moment(tableItem?.endDate as Date)],
            sampleCount: tableItem.sampleCount,
            samplePrice: tableItem.samplePrice,
            promotionalMainLine: tableItem?.promotionalSublines?.map((subline) => subline?.promotionalLineId)?.filter((id, index, self) => self.indexOf(id) === index) || [],
            promotionalSublines: tableItem?.promotionalSublines?.map((subline) => subline.id) || [],
            samples: tableItem?.samples?.map((s) => s.name).join(','),
        }
    }
}
