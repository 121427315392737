import React from "react";
import { Table } from "antd";
import { IPromoLineItem } from "@hooks/interfaces";
import { ColumnsType } from "antd/es/table";
import styles from "@pages/PromotionSettings/style.module.scss";
import { EditOutlined } from "@ant-design/icons";

type PromoLinesTableProps = {
    data: IPromoLineItem[],
    loading: boolean;
    onRowClick: (rowData: IPromoLineItem) => void
}
export const PromoLinesTable: React.FC<PromoLinesTableProps> = ({data, loading, onRowClick}) => {
    const columns: ColumnsType<IPromoLineItem> = [
        {
            title: "Название",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Сублинейки",
            key: "promotionalSublines",
            dataIndex: "promotionalSublines",
            render: (_, record) => {
                const parsedData = record.promotionalSublines?.map((sub) => sub.name)?.join(', ') || '';
                return <div>{parsedData}</div>
            }
        },
        {
            title: "Действия",
            width: "200px",
            align: "center",
            render: (_, record: IPromoLineItem) => (
                <div className={styles.icons}>
                    <EditOutlined onClick={() => {
                        onRowClick(record);
                    }} className={styles.editIcon} />
                </div>
            )
        },
    ]

    return (
        <div className={styles.tableWrapper}>
            <Table columns={columns} dataSource={data} loading={loading} />
        </div>
    );
};
