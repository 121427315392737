import { useState } from "react";
import axios from "@axios";
import { useNotification } from "@hooks/UI/useNotification";
import { GiftArg, IPromotionForm, PromoLineArg, PurchaseSumArg, SublineArg } from "@hooks/interfaces";

export const usePromotionsAPI = () => {
    const { UseDefaultNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const getPromoTypes = async () => {
        setLoading(true);
        try {
            return await axios.get("/stock/types");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка типов акций",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };

    const getPromoLinesWithSublines = async () => {
        setLoading(true);
        try {
            return await axios.get("/promotional-subline");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка линеек и сублинеек",
                duration: 3
            });
        } finally {
            setLoading(false);
        }

    };

    const getAllExistingGifts = async () => {
        setLoading(true);
        try {
            return await axios.get("/gift?type=gift");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка подарков",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };
    const getAllExistingCashbacks = async () => {
        setLoading(true);
        try {
            return await axios.get("/gift?type=cashback");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка кэшбэков",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };
    //call only for promo type cashback
    const getCashback = async () => {
        setLoading(true);
        try {
            return await axios.get("/gift?type=cashback");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка кэшбеков",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };
    // call only for type GIFT FOR PURCHASE
    const getSumsWithGiftsForTypeGift = async () => {
        setLoading(true);
        try {
            return await axios.get("/purchase-amount?type=gift");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка сумм и подарков",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };

    const getSumsWithGiftsForTypeCashback = async () => {
        setLoading(true);
        try {
            return await axios.get("/purchase-amount?type=cashback");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка сумм и подарков",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    };

    const createPromotion = async (payload: IPromotionForm) => {
        setLoading(true);
        try {
            return await axios.post("/stock", payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при создании акции",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const updatePromotion = async (payload: IPromotionForm) => {
        setLoading(true);
        try {
            return await axios.put(`/stock/${payload.id}`, payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при редактировании акции",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const getPromotionsList = async () => {
        setLoading(true);
        try {
            return await axios.get("/stock");
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при получении списка акций",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    //SETTINGS
    const createMainLine = async (payload: PromoLineArg) => {
        setLoading(true);
        try {
            return await axios.post("/promotional-line", payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при создании линейки",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const createSubLine = async (payload: SublineArg) => {
        setLoading(true);
        try {
            return await axios.post("/promotional-subline", payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при создании сублинейки",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const deleteSubline = async (id: number) => {
        setLoading(true);
        try {
            return await axios.delete(`/promotional-subline/${id}`);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при удалении сублинейки",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const createGift = async (payload: GiftArg) => {
        setLoading(true);
        try {
            return await axios.post("/gift", payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при создании подарка",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const createPurchaseSum =  async (payload: PurchaseSumArg) => {
        setLoading(true);
        try {
            return await axios.post("/purchase-amount", payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при создании суммы покупки",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    const updatePurchaseSum =  async (payload: PurchaseSumArg) => {
        setLoading(true);
        try {
            return await axios.put(`/purchase-amount/${payload.id}`, payload);
        } catch (err) {
            console.log({ err });
            UseDefaultNotification("error", {
                message: "Ошибка",
                description:"Ошибка при редактировании суммы покупки",
                duration: 3
            });
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        getPromoTypes,
        getPromoLinesWithSublines,
        getAllExistingGifts,
        getAllExistingCashbacks,
        getCashback,
        getSumsWithGiftsForTypeGift,
        getSumsWithGiftsForTypeCashback,
        createPromotion,
        updatePromotion,
        getPromotionsList,
        createMainLine,
        createSubLine,
        deleteSubline,
        createGift,
        createPurchaseSum,
        updatePurchaseSum
    };
};