import React, { useEffect, useMemo, useState } from "react";
import {Header, MaterialsTable, CreateReferenceForm, EditReferenceForm} from "@pages/DemoMaterials/components";
import {useDemoMaterials} from "@hooks/useContext";
import styles from './style.module.scss'
import { IsIterableArray, IsNullOrUndefined } from "@utils/helpers";
import { useDemoMaterialsAPI } from "@api/demomaterials";
import { IDropdownOption, IReferenceItem, ITableItem } from "@hooks/interfaces";
import { useNotification } from "@hooks/UI/useNotification";

export const DemoMaterialsPage = () => {
    const {UseDefaultNotification} = useNotification();
    const {initialDataForCreateReference, initialDataForEditReference, setInitialDataForCreateReference, setInitialDataForEditReference, groupOptions, setGroupOptions, isRefreshRequired, setIsRefreshRequired} = useDemoMaterials();
    const {getDemoMaterialsList, loading} = useDemoMaterialsAPI();
    const [data, setData] = useState<Array<{id: number, name: string, referenceItems: IReferenceItem[]}>>([])

    const GetData = async () => {
        try {
            const response = await getDemoMaterialsList();
            setData(response?.data ?? []);
            setIsRefreshRequired(false);
            if(!IsIterableArray(groupOptions)) {
                const groupOptions: IDropdownOption[] = response?.data?.map((item) => ({value: item.id, label: item.name})) || [];
                setGroupOptions(groupOptions);
            }
        } catch (error) {
            UseDefaultNotification("error", {
                message: "Ошибка",
                description: "Ошибка при загрузке списка референсов.",
                duration: 5
            });
            console.log({error});
        }
    }

    useEffect(() => {
        GetData().then();
    }, [])

    useEffect(() => {
        if(isRefreshRequired) {
            GetData().then();
        }
    }, [isRefreshRequired]);

    const tableData: ITableItem[] = useMemo(() => data?.reduce((acc: any[], current) => {
        const items = current?.referenceItems?.map((item) => ({...item, referenceGroupId: current.id, groupName: current.name, key: item.id})) || [];
        return [...acc, ...items];
    }, []) , [data]) || [];

    return (
        <div className={styles.container}>
            <Header />
            <MaterialsTable data={tableData} loading={loading} />
            {!IsNullOrUndefined(initialDataForCreateReference) && <CreateReferenceForm onClose={() => setInitialDataForCreateReference(null)}  />}
            {!IsNullOrUndefined(initialDataForEditReference) && <EditReferenceForm onClose={() => setInitialDataForEditReference(null)}  />}
        </div>
    );
};
