import React, { useEffect, useState } from "react";
import { GiftsTabHeader, GiftsTable, CreateGiftForm, EditGiftForm } from "@pages/PromotionSettings/components";
import styles from "@pages/PromotionSettings/style.module.scss";
import { ISumItem } from "@hooks/interfaces";
import { usePromotionsAPI } from "@api/promotions";
import { usePromotions } from "@hooks/useContext";
import { IsNullOrUndefined } from "@utils/helpers";

export const GiftsAndCashbackTab = () => {
    const api = usePromotionsAPI();
    const promotionStore = usePromotions();
    const [isRefreshRequired, setIsRefreshRequired] = useState(false);
    const [isRefreshGiftsRequired, setIsRefreshGiftsRequired] = useState(false);
    const [isRefreshCashbackRequired, setIsRefreshCashbacksRequired] = useState(false);
    const [isCreateGift, setIsCreateGift] = useState(false);
    const [initialDataForEditGift, setInitialDataForEditGift] = useState<ISumItem | null>(null);
    const [data, setData] = useState<ISumItem[]>([]);

    const handleRefresh = () => {
        setIsRefreshRequired(true);
        setIsRefreshGiftsRequired(true);
        setIsRefreshCashbacksRequired(true);
    };

    const getAllGifts = async () => {
        setIsRefreshGiftsRequired(false);
        const response = await api.getAllExistingGifts();
        promotionStore.setAllExistingGifts(response?.data ?? []);
    };
    const getAllCashbacks = async () => {
        setIsRefreshCashbacksRequired(false);
        const response = await api.getAllExistingCashbacks();
        promotionStore.setAllExistingCashbacks(response?.data ?? []);
    };


    const getAllSums = async () => {
        setIsRefreshRequired(false);
        const giftsResponse = await api.getSumsWithGiftsForTypeGift();
        const cashbackResponse = await api.getSumsWithGiftsForTypeCashback();
        const result = [...giftsResponse?.data ?? [], ...cashbackResponse?.data ?? []]?.map((item: ISumItem) => ({
            ...item,
            key: item.id
        })) || [];
        setData(result);
    };

    useEffect(() => {
        getAllSums().then();
        getAllGifts().then();
        getAllCashbacks().then();
    }, []);

    useEffect(() => {
        if (isRefreshRequired) {
            getAllSums().then();
        }
        if (isRefreshGiftsRequired) {
            getAllGifts().then();
        }
        if (isRefreshCashbackRequired) {
            getAllCashbacks().then();
        }
    }, [isRefreshGiftsRequired, isRefreshGiftsRequired, isRefreshCashbackRequired]);


    return (
        <div className={styles.moduleRoot}>
            <GiftsTabHeader onCreateClick={() => setIsCreateGift(true)} />
            <GiftsTable data={data} loading={api.loading} onRowClick={setInitialDataForEditGift} />
            {isCreateGift && <CreateGiftForm onClose={() => setIsCreateGift(false)} refresh={handleRefresh} />}
            {!IsNullOrUndefined(initialDataForEditGift) &&
                <EditGiftForm onClose={() => setInitialDataForEditGift(null)} initialFormValues={initialDataForEditGift}
                              refresh={handleRefresh} />}
        </div>
    );
};